

















import { Research } from '@app/models';
import { Block } from '@bcase/module-editor';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';

import { company } from '../../store/modules/company-module';
import { NavGuard } from '../../utils/decorators';

@Component
export default class ResearchInvitation extends Vue {
  public preview = false;

  @Prop()
  public value!: Research.Mutable;

  public get whitelist() {
    // Only five questions are allowed
    // const questions =
    //   this.value.questions.filter(e => e.type !== 'text').length >= 5
    //     ? []
    //     : ['checkbox', 'input', 'matrix', 'radio', 'slider'];

    const questions = ['checkbox', 'input', 'matrix', 'radio', 'slider'];
    return ['text', ...questions].join(',');
  }

  public get block() {
    return { ...Block.empty(), elements: this.value.questions || [] };
  }

  public set block(value: Block) {
    // If statement is a dirty fix to prevent infinite update loop in 'block'
    // watcher.
    if (JSON.stringify(value) !== JSON.stringify(this.block))
      this.value.questions = [...value.elements];
  }

  mounted() {
    this.watchBlock(this.block);
  }

  @Watch('block')
  public watchBlock(value?: Block) {
    const editor = this.$refs['block-editor'] as HTMLBceBlockEditorElement;
    // editor.resolveLocation = async location => this.storage.file(location);
    editor.value = value || Block.empty();
  }

  @NavGuard
  public beforeRouteEnter(to: Route, from: Route, next: Function) {
    if (company.license === 'plus' || company.license === 'pro') return next();

    next({
      name: 'research-respondents',
      params: to.params,
      query: to.query,
    });
  }
}
